import React, { Component } from "react"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle, faAngleLeft, faAngleRight, faSyncAlt, faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import ReactTooltip from "react-tooltip"

import { languageOptions } from "../util"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import DropdownMenu from "../Radiate/DropdownMenu/DropdownMenu"

import * as Styles from "../MessageTemplate/MessageTemplateStyles"
const SERVER_API_PATH = process.env.REACT_APP_SERVER_API_PATH

class ListMessageTemplate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      loading: false,
    }

    this.getData = this.getData.bind(this)
  }
  componentDidMount() {
    if (!_.isEmpty(this.props.payload) && this.props.signedContext) {
      this.getData({})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !_.isEqual(this.props.payload, prevProps.payload) && !_.isEmpty(this.props.payload) &&
      this.props.signedContext !== prevProps.signedContext && this.props.signedContext
    ) {
      this.getData({})
    }
  }

  async getData({ before, after }) {
    this.setState({
      loading: true
    })
    let body = {
      payload: this.props.payload,
      signedContext: this.props.signedContext,
      first: 10
    }
    if (before) {
      body.before = before
    }
    if (after) {
      body.after = after
    }
    const result = await fetch(`${SERVER_API_PATH}/get-whatsapp-message-templates`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    }).then(res => res.json())
    if (result?.ok) {
      this.setState({
        data: result,
        loading: false
      }, () => {
        ReactTooltip.rebuild()
      })
    }
  }

  async deleteMessageTemplate(name) {
    this.setState({
      loading: true
    })
    const result = await fetch(`${SERVER_API_PATH}/delete-whatsapp-message-template`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payload: this.props.payload,
        signedContext: this.props.signedContext,
        name
      })
    }).then(res => res.json())
    this.setState({
      loading: false
    }, () => {
      ReactTooltip.rebuild()
    })
    if (!result.success) {
      alert("Delete fails.")
    }
  }

  render() {
    const data = this.state?.data?.data || []
    const pageInfo = this.state?.data?.paging || {}
    return (
      <div style={{ height: "100%" }}className="whatsapp-message-template-main-container">
        <Styles.TopBar>
          <div className="message-template-top-bar">
            <NewBoxButton
              style={{ fontSize: "1rem" }}
              className="create-button"
              primary
              text="Create"
              onClick={() => {
                this.props.onChangePage("create")
              }}
            />
            <div style={{ display: "flex" }}>
              <Styles.SegmentedButtonContainer>
                <NewBoxButton
                  style={{ color: "#707070"}}
                  disabled={!pageInfo?.hasPrevious}
                  icon={faAngleLeft}
                  onClick={() => {
                    this.getData({ before: pageInfo?.cursors?.before })
                  }}
                />
                <NewBoxButton
                  style={{ color: "#707070" }}
                  disabled={!pageInfo?.hasNext}
                  icon={faAngleRight}
                  onClick={() => {
                    this.getData({ after: pageInfo?.cursors?.after })
                  }}
                />
              </Styles.SegmentedButtonContainer>
              <NewBoxButton
                style={{ color: "#707070" }}
                className="reload-button"
                inverted
                primary
                icon={faSyncAlt}
                onClick={() => {
                  this.getData({})
                }}
              />
            </div>
          </div>
        </Styles.TopBar>
        <Styles.WhatsappMessageTemplateContainer className="whatsapp-message-template-container">
          {this.state.loading ?
            <Styles.LoadingContainer>
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </Styles.LoadingContainer>
            :
            <Styles.TableContainer>
              <Styles.StyledTable
                data={data}
                noOuterBorder
                className="whatsapp-message-templates-table"
                customKey="id"
                displayFields={[{
                  label: "Template Name",
                  key: "name"
                }, {
                  label: "Category",
                  key: "category",
                  custom: (category) => {
                    let text
                    switch (category) {
                      case "ACCOUNT_UPDATE":
                        text = "Account Update"
                        break
                      case "PAYMENT_UPDATE":
                        text = "Payment Update"
                        break
                      case "PERSONAL_FINANCE_UPDATE":
                        text = "Personal Finance Update"
                        break
                      case "SHIPPING_UPDATE":
                        text = "Shipping Update"
                        break
                      case "RESERVATION_UPDATE":
                        text = "Reservation Update"
                        break
                      case "ISSUE_RESOLUTION":
                        text = "Issue Resolution"
                        break
                      case "APPOINTMENT_UPDATE":
                        text = "Appointment Update"
                        break
                      case "TRANSPORTATION_UPDATE":
                        text = "Transportation Update"
                        break
                      case "TICKET_UPDATE":
                        text = "Ticket Update"
                        break
                      case "ALERT_UPDATE":
                        text = "Alert Update"
                        break
                      case "AUTO_REPLY":
                        text = "Auto Reply"
                        break
                      default:
                        text = ""
                        break
                    }
                    return (
                      <div>{text}</div>
                    )
                  }
                }, {
                  label: "Preview",
                  key: "templates",
                  custom: (templates) => {
                    return templates.map((o) => {
                      const { components, status, language, rejected_reason } = o
                      const newComponents = [...components, { status }, { language }, { rejectReason: rejected_reason }]
                      return (
                        <Styles.Status
                          key={language}
                          status={status}
                          data-tip={JSON.stringify(newComponents)}
                          data-for="whatsapp-template-tooltip"
                        >
                          <div className="language-status-wrapper">
                            <FontAwesomeIcon
                              icon={faCircle}
                              className="icon"
                            />
                            <div className="language">
                              {languageOptions.find(lang => lang.value === language).label}
                            </div>
                          </div>
                        </Styles.Status>
                      )
                    })
                  }
                },
                {
                  label: "",
                  key: "name",
                  custom: name => (
                    <DropdownMenu
                      inverted
                      placement="bottom-end"
                      buttons={[
                        {
                          label: "Delete",
                          icon: "trash",
                          danger: true,
                          onClick: async () => {
                            await this.deleteMessageTemplate(name)
                            this.getData({})
                          }
                        }
                      ]}
                    />
                  )
                }
                ]}
              />
              {/* {pageInfo?.hasNextPage ?
                <Styles.LoadMoreRow>
                  <NewBoxButton
                    primary
                    borderless
                    icon="chevron-down"
                    text="Load more"
                    onClick={this.loadMoreConnection}
                    loading={this.state.loadingMore}
                  />
                </Styles.LoadMoreRow>
                :
                null
              } */}
              <Styles.StyledTooltip
                id="whatsapp-template-tooltip"
                getContent={(content) => {
                  if (!content) {
                    return null
                  }
                  const json = JSON.parse(content)
                  const header = json.find(v => v.type === "HEADER")
                  const body = json.find(v => v.type === "BODY")
                  const footer = json.find(v => v.type === "FOOTER")
                  const buttons = json.find(v => v.type === "BUTTONS")
                  const status = json.find(v => v.status)
                  const language = json.find(v => v.language)
                  const rejectReason = json.find(v => v.rejectReason)
                  return (
                    <Styles.TooltipContent>
                      <div style={{ display: "flex", marginBottom: "16px" }}>
                        <div style={{ padding: "4px", background: "#555", borderRadius: "4px", fontWeight: "bold" }}>{_.capitalize(status.status)}</div>
                        <div style={{ marginLeft: "8px", padding: "4px", background: "#555", borderRadius: "4px", fontWeight: "bold" }}>{language.language}</div>
                      </div>
                      {(rejectReason.rejectReason !== "NONE") && (
                        <div>{`Reject Reason:\n${rejectReason.rejectReason}\n\n`}</div>
                      )}
                      <div>{header && header.format === "TEXT" ? `Header:\n${header.text}\n\n` : ""}</div>
                      <div>{header && (header.format === "IMAGE" || header.format === "VIDEO" || header.format === "DOCUMENT") ? `Header:\n${header.format}\n\n` : ""}</div>
                      <div style={{ wordBreak: "break-word" }}>{`Body:\n${body.text}\n\n`}</div>
                      <div>{footer ? `\nFooter:\n${footer.text}` : ""}</div>
                      {buttons && buttons.buttons && buttons.buttons.length ?
                        <div>
                          <br />
                          <div>Buttons:</div>
                          <Styles.ButtonPreview >
                            <table style={{ borderCollapse: "collapse" }}>
                              <thead>
                                <tr>
                                  <td style={{ fontWeight: "bold", padding: "4px", borderBottom: "1px solid #555", borderRight: "1px solid #555" }}>TYPE</td>
                                  <td style={{ fontWeight: "bold", padding: "4px", borderBottom: "1px solid #555", borderRight: "1px solid #555" }}>TEXT</td>
                                  <td style={{ fontWeight: "bold", padding: "4px", borderBottom: "1px solid #555" }}>URL / PHONE NUMBER</td>
                                </tr>
                              </thead>
                              <tbody>
                                {buttons.buttons.map(button => (
                                  <tr style={{ width: "100%" }} key={button.text}>
                                    <td style={{ padding: "4px", borderRight: "1px solid #555" }}>{button.type}</td>
                                    <td style={{ padding: "4px", borderRight: "1px solid #555" }}>{button.text}</td>
                                    <td style={{ padding: "4px", flexGrow: 1 }}>{button.url || button.phone_number || ""}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </Styles.ButtonPreview>

                        </div>
                        :
                        null
                      }
                    </Styles.TooltipContent>
                  )
                }}
                place="right"
                effect="solid"
              />
            </Styles.TableContainer>
          }
          
        </Styles.WhatsappMessageTemplateContainer>
      </div>
    )
  }  
}

export default ListMessageTemplate