/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { Component } from "react"
import Select from "react-select"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera, faCheckCircle, faCircleNotch, faExclamationCircle, faExternalLinkAlt, faCircle } from '@fortawesome/free-solid-svg-icons'
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"
import PinInput from "../Radiate/PinInput/PinInput"

import * as Styles from "./SettingsStyles"
import { categoryOptions } from "../../components/util"

const SERVER_API_PATH = process.env.REACT_APP_SERVER_API_PATH

class Settings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profileData: {},
      originalProfileData: {},
      loadingProfile: false,
      updatingProfile: false,
      updateSuccess: false,
      updateErrorMessage: "",
      uploadProfilePicStatus: "",
      unsubscribe: false,
      resubscribing: false,
      reconnecting: false,
      reconnectError: null,
      reconnectSuccess: false,
      displayNameStatus: null,
      getProfileError: null,
    }
    this.updateWhatsappProfile = this.updateWhatsappProfile.bind(this)
    this.resumableUpload = this.resumableUpload.bind(this)
  }

  componentDidMount() {
    if (this.props.info?.phoneNumberId && this.props.payload && this.props.signedContext) {
      this.getWhatsAppProfile(true)
      this.getDisplayNameAndStatus()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.info, this.props.info) && this.props.info?.phoneNumberId) {
      this.getWhatsAppProfile(true)
    }
  }

  async getWhatsAppProfile(withLoading) {
    this.setState({
      getProfileError: null
    })
    if (withLoading) {
      this.setState({
        loadingProfile: true
      })
    }
    const result = await fetch(`${SERVER_API_PATH}/get-whatsapp-business-profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phoneNumberId: this.props.info?.phoneNumberId,
        payload: this.props.payload,
        signedContext: this.props.signedContext,
      }),
    }).then(res => res.json())
    this.setState({
      loadingProfile: false,
    })
    if (result.ok === 0) {
      this.setState({
        getProfileError: result?.error || "Fail to get business profile settings."
      })
    } else {
      this.setState({
        profileData: result.data || {},
        originalProfileData: result.data || {},
      })
    }
  }

  async updateWhatsappProfile(data) {
    this.setState({
      updatingProfile: true,
      updateSuccess: false
    })
    const values = _.cloneDeep(data)
    if (values) {
      values.messaging_product = "whatsapp"
    }
    const result = await fetch(`${SERVER_API_PATH}/update-whatsapp-business-profile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payload: this.props.payload,
        signedContext: this.props.signedContext,
        profile: values,
        phoneNumberId: this.props.info?.phoneNumberId
      }),
    }).then(res => res.json())
    this.setState({
      updatingProfile: false,
    })
    if (result.ok === 0) {
      this.setState({
        updateErrorMessage: result.error
      })
    } else {
      this.setState({
        updateSuccess: true,
        fileName: "",
        updateErrorMessage: ""
      }, () => {
        this.getWhatsAppProfile()
      })
    }
  }

  async resumableUpload(files) {
    this.setState({
      uploadProfilePicStatus: "loading"
    })
    const uploadedFile = files[0]
    // console.log("uploadedFile", uploadedFile)
    if (!uploadedFile.type.includes("jpeg") && !uploadedFile.type.includes("jpg") && !uploadedFile.type.includes("png")) {
      // setUploadStatus("failure")
      this.setState({
        uploadProfilePicStatus: "failure"
      })
      return alert("Unsupported image format")
    }

    const params = {
      fileName: uploadedFile.name,
      fileType: uploadedFile.type,
      phoneNumber: this.props.info?.phoneNumber,
      phoneNumberId: this.props.info?.phoneNumberId,
      tags: [{
        key: "Type",
        value: "MediaFileForProfilePic"
      }]
    }

    const json = await fetch(`${SERVER_API_PATH}/sign-url`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(response => response.json())
    const form = new FormData()
    if (json.ok) {
      _.forEach(json?.data?.fields, (value, key) => {
        form.append(key, value)
      })
      form.append("file", uploadedFile)
      const uploadToS3Response = await fetch(json.data.url, {
        method: "POST",
        body: form,
      })
      // console.log("uploadToS3Response", uploadToS3Response)
      if (uploadToS3Response.status === 204) {
        const split = json?.data?.fields.key.split("/")
        const encodedSplit = split.map(s => encodeURIComponent(s))
        const fileUrl = `${json?.data?.url}/${encodedSplit.join("/")}`
        try {
          const upload = await fetch(`${SERVER_API_PATH}/resumable-upload`, {
            method: "POST",
            body: JSON.stringify({
              url: fileUrl
            }),
            headers: {
              "Content-Type": "application/json"
            }
          }).then(response => response.json())
          this.setState({
            uploadProfilePicStatus: "success",
            filename: split[split.length - 1],
            profileData: {
              ...this.state.profileData,
              profile_picture_handle: upload?.handle
            }
          })
        } catch (error) {
          this.setState({
            uploadProfilePicStatus: "Operation exceeded time limit.",
          })
        }
      } else {
        this.setState({
          uploadProfilePicStatus: "File cannot be uploaded to S3.",
        })
      }
      return null
    } else {
      this.setState({
        uploadProfilePicStatus: json?.error
      })
      throw new Error(json.error)
    }
  }

  async resubscribe() {
    this.setState({
      resubscribing: true
    })
    const data = {
      channelId: this.props.payload?.channel,
    }
    const result = await fetch(`${SERVER_API_PATH}/resubscribe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
    }).then(res => res.json())
    if (result.ok) {
      this.setState({
        unsubscribe: false,
        resubscribing: false
      })
    } else {
      this.setState({
        unsubscribe: true,
        resubscribing: false
      })
    }
  }

  async reconnect() {
    this.setState({
      reconnecting: true,
      reconnectError: null,
      reconnectSuccess: false
    })
    const body = JSON.stringify({
      payload: this.props.payload,
      signedContext: this.props.signedContext,
    })
    const result = await fetch(`${SERVER_API_PATH}/reconnect-whatsapp-cloud`, {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => res.json())
    this.setState({
      reconnecting: false
    })
    if (result.ok) {
      this.setState({
        pin: "",
        reconnectSuccess: true
      }, () => {
        this.getDisplayNameAndStatus()
        this.pinRef.current.reset()
      })
    } else {
     this.setState({
       reconnectError: result?.error || "Deployment failed."
     })
    }
  }

  async getDisplayNameAndStatus() {
    const body = JSON.stringify({
      payload: this.props.payload,
      signedContext: this.props.signedContext,
      phoneNumberId: this.props.info?.phoneNumberId
    })
    const result = await fetch(`${SERVER_API_PATH}/get-whatsapp-displayname-status`, {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => res.json())
    if (result.ok) {
      this.setState({
        displayNameStatus: result?.data
      })
    }
  }

  uploadProfilePicDisplay() {
    const { uploadProfilePicStatus, filename } = this.state
    if (uploadProfilePicStatus === "loading") {
      return <Styles.ProfilePicStatusContainer>
        <FontAwesomeIcon
          icon={faCircleNotch}
          spin
        />
        <span style={{ paddingLeft: "4px" }}>Loading...</span>
      </Styles.ProfilePicStatusContainer>
    }

    if (uploadProfilePicStatus !== "loading" && uploadProfilePicStatus !== "success" && uploadProfilePicStatus?.length) {
      return <Styles.ProfilePicStatusContainer>
        <FontAwesomeIcon
          icon={faExclamationCircle}
        />
        <span style={{ paddingLeft: "4px" }}>{uploadProfilePicStatus}</span>
      </Styles.ProfilePicStatusContainer>
    }

    if (filename && uploadProfilePicStatus === "success") {
      return <Styles.Filename>{filename}</Styles.Filename>
    }
  }

  qualityRatingDisplay(qr) {
    let rating
    switch (qr) {
      case "GREEN":
        rating = "High"
        break
      case "YELLOW":
        rating = "Medium"
        break
      case "RED":
        rating = "Low"
        break
      default:
        rating = qr
    }
    return rating
  }

  renderProfileSettings() {
    const {
      loadingProfile,
      profileData,
      originalProfileData,
      updateSuccess,
      updateErrorMessage,
      getProfileError
    } = this.state
    if (loadingProfile) {
      return (
        <Styles.LoadingContainer>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </Styles.LoadingContainer>
      )
    }
    if (getProfileError) {
      return (
        <div 
          style={{ 
            display: "flex", 
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: "0.75rem",
            color: "#d93920"
          }}
        >
          {getProfileError}
        </div>
      )
    }

    return (
      <>
        <div style={{ display: "flex", alignItems: "center", paddingBottom: "16px" }}>
          {profileData?.profile_picture_url ?
            <img
              src={profileData.profile_picture_url}
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "40px",
                border: "1px solid #e8e7e8"
              }}
            />
            :
            <Styles.MockImage>
              <FontAwesomeIcon
                icon={faCamera}
              />
            </Styles.MockImage>
          }
          <div style={{ display: "flex", alignItems: "center", paddingLeft: "8px" }}>
            <Styles.UploadLabel htmlFor="upload-file">
              <Styles.UploadFileButton>
                {profileData.profile_picture_url ? "Edit Profile Picture" : "Add Profile Picture"}
              </Styles.UploadFileButton>
              <Styles.FileInput
                type="file"
                id="upload-file"
                onChange={(e) => {
                  this.resumableUpload(e.target.files)
                }}
              />
            </Styles.UploadLabel>
            {this.uploadProfilePicDisplay()}
          </div>
        </div>

        <div style={{ paddingBottom: "8px" }}>
          <div style={{ fontSize: "0.7rem", color: "#a5a5a6" }}>Address</div>
          <Styles.TextInput
            label="Address"
            placeholder="Address"
            value={profileData?.address}
            onChange={(e) => {
              const clone = _.cloneDeep(profileData)
              clone.address = e.target.value
              this.setState({
                profileData: clone
              })
            }}
          />
        </div>

        <div style={{ paddingBottom: "8px" }}>
          <div style={{ fontSize: "0.7rem", color: "#a5a5a6" }}>Description</div>
          <Styles.TextInput
            label="Description"
            placeholder="Description"
            value={profileData?.description}
            onChange={(e) => {
              const clone = _.cloneDeep(profileData)
              clone.description = e.target.value
              this.setState({
                profileData: clone
              })
            }}
          />
        </div>

        <div style={{ paddingBottom: "8px" }}>
          <div style={{ fontSize: "0.7rem", color: "#a5a5a6" }}>Email</div>
          <Styles.TextInput
            label="Email"
            placeholder="Email"
            value={profileData?.email}
            onChange={(e) => {
              const clone = _.cloneDeep(profileData)
              clone.email = e.target.value
              this.setState({
                profileData: clone
              })
            }}
          />
        </div>

        <div style={{ paddingBottom: "7px" }}>
          <div style={{ fontSize: "0.7rem", color: "#a5a5a6", paddingBottom: "2px" }}>Business Category (Service Type)</div>
          <Select
            options={categoryOptions}
            value={categoryOptions.find(o => o.value === profileData?.vertical)}
            onChange={(value) => {
              const clone = _.cloneDeep(profileData)
              clone.vertical = value.value
              this.setState({
                profileData: clone
              })
            }}
          />
        </div>

        <div style={{ paddingBottom: "8px" }}>
          <div style={{ fontSize: "0.7rem", color: "#a5a5a6" }}>Website 1</div>
          <Styles.TextInput
            label="Website 1"
            placeholder="Website 1"
            value={profileData?.websites?.length ? profileData?.websites[0] : ""}
            onChange={(e) => {
              const clone = _.cloneDeep(profileData)
              if (_.isArray(clone.websites)) {
                clone.websites[0] = e.target.value
              } else {
                clone.websites = [e.target.value]
              }

              this.setState({
                profileData: clone
              })
            }}
          />
        </div>

        <div style={{ paddingBottom: "16px" }}>
          <div style={{ fontSize: "0.7rem", color: "#a5a5a6" }}>Website 2</div>
          <Styles.TextInput
            label="Website 2"
            placeholder="Website 2"
            disabled={!profileData?.websites?.length}
            value={profileData?.websites?.length ? profileData?.websites[1] : ""}
            onChange={(e) => {
              const clone = _.cloneDeep(profileData)
              clone.websites[1] = e.target.value
              this.setState({
                profileData: clone
              })
            }}
          />
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <NewBoxButton
            style={{ fontSize: "1rem", minWidth: "108px" }}
            primary
            text="Update Profile"
            disabled={_.isEqual(profileData, originalProfileData)}
            onClick={() => {
              this.updateWhatsappProfile(this.state.profileData)
            }}
          />
          {updateSuccess &&
            <Styles.ProfileStatus success>
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="success-icon"
              />
              <span>Profile updated.</span>
            </Styles.ProfileStatus>
          }
          {updateErrorMessage &&
            <Styles.ProfileStatus>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="error-icon"
              />
              <span>{updateErrorMessage}</span>
            </Styles.ProfileStatus>
          }
        </div>
      </>
    )
  }

  render() {
    const {
      reconnecting, 
      reconnectError,
      reconnectSuccess,
      displayNameStatus,
    } = this.state
    const { info } = this.props
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "center", padding: "32px 8px 32px 8px" }}>
          <div style={{ width: "30%", paddingRight: "48px", color: "#707070", boxSizing: "border-box" }}>
            <div style={{ fontWeight: "bold", fontSize: "20px" }}>WhatsApp Info</div>
          </div>
          <div style={{ width: "70%" }}>
            <table>
              <tbody>
                {info?.formattedPhoneNumber &&
                  <tr style={{ fontSize: "0.8rem" }}>
                    <td style={{ width: "120px", paddingBottom: "8px", color: "#707070" }}>WhatsApp Number</td>
                    <td style={{ fontWeight: "bold", paddingBottom: "8px", color: "#626262" }}>
                      <div>
                        {info?.formattedPhoneNumber}
                        {info?.phoneNumber &&
                          <Styles.ExternalLink
                            className="link"
                            href={`https://wa.me/${info?.phoneNumber}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon
                              className="icon"
                              icon={faExternalLinkAlt}
                            />
                          </Styles.ExternalLink>
                        }
                      </div>
                    </td>
                  </tr>
                }
                {info?.phoneDisplayName &&
                  <tr style={{ fontSize: "0.8rem" }}>
                    <td style={{ width: "120px", paddingBottom: "8px", color: "#707070" }}>Display Name</td>
                    <td style={{ fontWeight: "bold", paddingBottom: "8px", color: "#626262" }}>{info?.phoneDisplayName}</td>
                  </tr>
                }
                {info?.wabaId &&
                  <tr style={{ fontSize: "0.8rem" }}>
                    <td style={{ width: "120px", paddingBottom: "8px", color: "#707070" }}>WABA ID</td>
                    <td style={{ fontWeight: "bold", paddingBottom: "8px", color: "#626262" }}>{info?.wabaId}</td>
                  </tr>
                }
                {info?.phoneNumberId &&
                  <tr style={{ fontSize: "0.8rem" }}>
                    <td style={{ width: "120px", paddingBottom: "8px", color: "#707070" }}>Phone Number ID</td>
                    <td style={{ fontWeight: "bold", paddingBottom: "8px", color: "#626262" }}>{info?.phoneNumberId}</td>
                  </tr>
                }
                {displayNameStatus?.verified_name &&
                  <tr style={{ fontSize: "0.8rem" }}>
                    <td style={{ width: "120px", paddingBottom: "8px", color: "#707070" }}>Display Name</td>
                    <td style={{ fontWeight: "bold", paddingBottom: "8px", color: "#626262" }}>{displayNameStatus?.verified_name}</td>
                  </tr>
                }
                {displayNameStatus?.quality_rating &&
                  <tr style={{ fontSize: "0.8rem" }}>
                    <td style={{ width: "120px", paddingBottom: "8px", color: "#707070" }}>Quality Rating</td>
                    <td>
                      <Styles.QualityRating
                        className="data-details-content"
                        rating={displayNameStatus?.quality_rating}
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="icon"
                        />
                        {this.qualityRatingDisplay(displayNameStatus?.quality_rating)}
                      </Styles.QualityRating>
                    </td>
                  </tr>
                }
                {displayNameStatus?.account_mode &&
                  <tr style={{ fontSize: "0.8rem" }}>
                    <td style={{ width: "120px", paddingBottom: "8px", color: "#707070" }}>Account Mode</td>
                    <td style={{ fontWeight: "bold", paddingBottom: "8px", color: "#626262" }}>{displayNameStatus?.account_mode}</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
        <div style={{ height: "1px", background: "#e8e7e8" }} />
        <div style={{ display: "flex", justifyContent: "center", padding: "32px 8px 32px 8px" }}>
          <div style={{ width: "30%", paddingRight: "48px", color: "#707070", boxSizing: "border-box" }}>
            <div style={{ fontWeight: "bold", fontSize: "20px" }}>Business Profile Settings</div>
            <div style={{ lineHeight: "1.4", padding: "8px 0", fontSize: "0.9rem" }}>In the WhatsApp app, click on Chat with business account, then click on the name at the top to see the complete contact information including Business Details.</div>
          </div>
          <div style={{ width: "70%" }}>
            {this.renderProfileSettings()}
          </div>
        </div>
        <div style={{ height: "1px", background: "#e8e7e8" }} />
        <div style={{ display: "flex", justifyContent: "center", padding: "32px 8px 32px 8px" }}>
          <div style={{ width: "30%", paddingRight: "48px", color: "#707070", boxSizing: "border-box" }}>
            <div style={{ fontWeight: "bold", fontSize: "20px" }}>Reconnect</div>
            <div style={{ lineHeight: "1.4", padding: "8px 0", fontSize: "0.9rem" }}>Only use this option when you need to update your phone number's display name.</div>
          </div>
          <div style={{ width: "70%" }}>
            <div style={{ padding: "16px", borderRadius: "6px", border: "1px solid #fad037", backgroundColor: "#fffae6" }}>
              <div style={{ paddingTop: "8px" }}>
                <div style={{ fontSize: "0.7rem", color: "#a5a5a6" }}>6-Digit Registration Code</div>
                <PinInput
                  digit={6}
                  onChange={(value) => {
                    this.setState({
                    })
                  }}
                />
              </div>
              <div style={{ marginTop: "16px", display: "flex", alignItems: "center" }}>
                <NewBoxButton
                  style={{ fontSize: "1rem", minWidth: "68px" }}
                  primary
                  inverted
                  text="Confirm"
                  loading={reconnecting}
                  onClick={() => {
                    this.reconnect()
                  }}
                />
                {reconnectError &&
                  <span style={{ marginLeft: "8px", fontSize: "0.75rem", color: "#d93920" }}>{reconnectError}</span>
                }
                {reconnectSuccess &&
                  <span style={{ marginLeft: "8px", fontSize: "0.75rem", color: "#88c559" }}>Reconnect success.</span>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Settings
