/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { Component } from "react"
import { Helmet } from "react-helmet"
import _ from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"
import SetUp from "../../components/SetUp/SetUp"
import Settings from "../../components/Settings/Settings"
import MessageTemplate from "../../components/MessageTemplate/MessageTemplate"
import AccessTokenManager from "../../components/AccessTokenManager/AccessTokenManager"
import Tab from "../../components/Radiate/Tab/Tab"

import * as Styles from "./HomeStyles"

const SERVER_API_PATH = process.env.REACT_APP_SERVER_API_PATH
const DOMAIN = process.env.REACT_APP_WOZTELL_URL

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      info: {},
      loading: false,
      context: null,
      unsubscribe: false,
      resubscribing: false,
    }
    this.receiveMessage = this.receiveMessage.bind(this)
  }

  componentDidMount() {
    if (navigator.userAgent !== "ReactSnap") {
      window.addEventListener("message", this.receiveMessage, false)
      this.getDataFromDb()
      window.parent.postMessage("iframeFinishLoading", DOMAIN)
      window.parent.postMessage("iframeloading", DOMAIN)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevState.context, this.state.context) && this.state.context) {
      this.getDataFromDb()
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.receiveMessage, false)
  }

  receiveMessage(event) {
    if (event.origin !== DOMAIN) {
      return
    }
    if (event?.data) {
      if (event?.data?.unsubscribe) {
        this.setState({
          unsubscribe: true
        })
      } else {
        this.setState({
          context: event.data
        })
      }
    }
  }

  async getDataFromDb() {
    this.setState({
      loading: true
    })
    if (this.state.context) {
      const result = await fetch(`${SERVER_API_PATH}/get-whatsapp-cloud-info`, {
        method: "POST",
        body: JSON.stringify(this.state.context),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(res => res.json())
      if (result.ok) {
        this.setState({
          info: result?.data?.info,
          unsubscribe: result?.data?.unsubscribe,
          loading: false
        })
      } else {
        this.setState({
          loading: false
        })
      }
      // window.parent.postMessage("iframeFinishLoading", DOMAIN)
    }
  }

  renderInfo() {
    const { info, context, unsubscribe } = this.state
    if (this.state.loading) {
      return (
        <Styles.LoadingContainer>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </Styles.LoadingContainer>
      )
    } else if (_.isEmpty(info)) {
      return (
        <SetUp
          payload={context?.payload}
          signedContext={context?.signedContext}
          onGetDataFromDb={() => {
            this.getDataFromDb()
          }}
        />
      )
    } else if (info) {
      if (unsubscribe) {
        return (
          <Styles.Overlay>
            <div className="overlay-content">
              <div className="unsubscribe-text">This channel has been unsubscribed, and is not functional.</div>
              <div className="unsubscribe-text">No further charges will be incurred.</div>
              <NewBoxButton
                primary
                className="resubscribe-button"
                loading={this.state.resubscribing}
                text="Subscribe"
                onClick={() => {
                  this.resubscribe()
                }}
              />
            </div>
          </Styles.Overlay>
        )
      }
      return (
        <Tab
          newTheme
          tabs={[{
            id: "settings",
            text: "Settings",
            content: (
              <Settings
                info={this.state.info}
                payload={context?.payload}
                signedContext={context?.signedContext}
              />
            ),
            disabled: false,
          }, {
            id: "messageTemplate",
            text: "Message Template",
            content: (
              <MessageTemplate 
                info={this.state.info}
                payload={context?.payload}
                signedContext={context?.signedContext}
              />
            ),
            disabled: false,
          }, {
            id: "advancedAccess",
            text: "Advanced Access",
            content: (
              <AccessTokenManager
                info={this.state.info}
                payload={context?.payload}
                signedContext={context?.signedContext}
              />
            )
          }]}
        />
      )
    }
  }

  render() {
    return (
      <div style={{ height: "100%" }}>
        <Helmet>
          <title>WhatsApp Cloud API Integration</title>
        </Helmet>
        <Styles.BodyContainer>
          {this.renderInfo()}
        </Styles.BodyContainer>
      </div>
    )
  }
}

export default Home
