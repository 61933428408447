/* eslint-disable no-undef */

import React, { useState, useEffect, useReducer, useCallback } from "react"
import styled from "styled-components"
import Select from "react-select"
import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import PinInput from "../Radiate/PinInput/PinInput"

const SERVER_API_PATH = process.env.REACT_APP_SERVER_API_PATH

const INITIAL_STATE = {
  wabaId: "",
  name: "",
  namespace: "",
  phoneNumber: "",
  phoneNumberCC: "",
  accountMode: "",
  formattedPhoneNumber: "",
  phoneDisplayName: "",
  phoneNumberId: "",
  pin: ""
}

const Tag = styled.div`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  background: ${props => props.theme.themeText};
  color: #fff;
  font-weight: normal;
  font-size: ${props => props.theme.textSm};
  ${props => props.extend}
`

const TextArea = styled.textarea`
  font-size: 0.8rem;
  color: #626262;
  outline: none;
  margin-right: 4px;
  padding: 6px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow-y: scroll;
  overflow-wrap: break-word;
  resize: none;
  width: 100%;
  min-height: 100px;
  box-sizing: border-box;
  font-family: sans-serif;
  background-color: ${props => props.disabled && props.theme.paleBackground};
`

const TextInput = styled.input`
  font-size: 1em;
  color: #626262;
  outline: none;
  margin-bottom: 3px;
  margin-top: 2px;
  margin-right: 4px;
  padding: 6px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow: hidden;
  overflow-wrap: break-word;
  width: 100%;
  box-sizing: border-box;
  background-color: ${props => props.disabled && props.theme.paleBackground};
`

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        wabaId: action.payload.wabaId,
        name: action.payload.name,
        namespace: action.payload.namespace,
      }
    case "UPDATE_NUMBER":
      return {
        ...state,
        phoneNumber: action.payload.phoneNumber,
        phoneNumberCC: action.payload.phoneNumberCC,
        accountMode: action.payload.accountMode,
        formattedPhoneNumber: action.payload.formattedPhoneNumber,
        phoneDisplayName: action.payload.phoneDisplayName,
        phoneNumberId: action.payload.phoneNumberId
      }
    case "UPDATE_PIN":
      return {
        ...state,
        pin: action.payload.pin
      }
    case "UPDATE_BACKUP_DATA":
      return {
        ...state,
        backupData: action.payload.backupData,
      }
    case "UPDATE_BACKUP_PASSWORD":
      return {
        ...state,
        backupPassword: action.payload.backupPassword,
      }
    default:
      throw new Error(`${action.type} is not defined.`)
  }
}

const SetUp = ({
  payload,
  signedContext,
  onGetDataFromDb,
}) => {
  const [wabas, setWabas] = useState(null)
  const [fbUserToken, setFbUserToken] = useState(null)
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [deploying, setDeploying] = useState(false)
  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [variables, dispatch] = useReducer(reducer, INITIAL_STATE)
  const [deployError, setDeployError] = useState("")
  const [signUpError, setSignUpError] = useState("")
  const [migrationFlow, setMigrationFlow] = useState(false)

  useEffect(() => {
    if (wabas?.length && fbUserToken) {
      setStep(1)
    }
  }, [wabas, fbUserToken])

  useEffect(() => {
    if (variables?.wabaId) {
      getWhatsappNumbers()
    }
  }, [variables?.wabaId])

  const getWhatsappNumbers = useCallback(async () => {
    const result = await fetch(`${SERVER_API_PATH}/get-whatsapp-numbers`, {
      method: "POST",
      body: JSON.stringify({
        accountId: variables?.wabaId
      }),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => res.json())
    if (result.ok) {
      setPhoneNumbers(result.data)
    } else {
      console.log("Phone numbers not found")
    }
  })

  const finishWhatsappCloudSetup = async() => {
    setDeploying(true)
    setDeployError("")
    const body = JSON.stringify({
      payload,
      signedContext,
      pin: variables?.pin,
      backupData: variables?.backupData,
      backupPassword: variables?.backupPassword,
      info: {
        waId: variables.phoneNumber,
        phoneNumberId: variables.phoneNumberId,
        wabaId: variables.wabaId,
        name: variables.name,
        phoneNumber: variables.phoneNumber,
        phoneNumberCC: variables.phoneNumberCC,
        phoneDisplayName: variables.phoneDisplayName,
        formattedPhoneNumber: variables.formattedPhoneNumber,
        namespace: variables.namespace,
        embeddedSignup: true
      },
    })
    const result = await fetch(`${SERVER_API_PATH}/finish-whatsapp-cloud-setup`, {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => res.json())
    if (result.ok) {
      onGetDataFromDb()
      setDeploying(false)
    } else {
      setDeploying(false)
      setDeployError(result?.error || "Deployment failed.")
    }
  }


  function embedSignup() {
    setLoading(true)
    FB.login(async (response) => {
      console.log("response", response)
      if (response.authResponse) {
        const accessToken = response.authResponse.accessToken
        const result = await fetch(`${SERVER_API_PATH}/get-shared-waba-ids`, {
          method: "POST",
          body: JSON.stringify({
            token: accessToken
          }),
          headers: {
            "Content-Type": "application/json"
          }
        }).then(res => res.json())
        console.log("result", result)
        if (result.ok) {
          setWabas(result.wabas)
          setFbUserToken(accessToken)
          setLoading(false)
        } else {
          setSignUpError(result?.error || "User cancelled login or did not fully authorize.")
          setLoading(false)
        }
      }
    }, {
      scope: "business_management, whatsapp_business_management",
      extras: {
        feature: "whatsapp_embedded_signup",
      }
    })
  }

  const options = (wabas || []).map(waba => ({
    label: `${waba.name} (${waba.id})`,
    value: waba.id,
    name: waba.name,
    namespace: waba.message_template_namespace,
  }))
  let phoneNumberOptions = []
  if (phoneNumbers && phoneNumbers.length) {
    phoneNumberOptions = phoneNumbers.map((o) => {
      const waId = o.display_phone_number.replace(/(\+|-| )/g, "")
      let ready = true
      if (o.account_mode !== "SANDBOX") {
        if (o.new_name_status === "NONE") {
          // no new verified name
          if (o.name_status !== "APPROVED") {
            ready = false
          }
        } else if (o.new_name_status !== "APPROVED") {
          ready = false
        }
      }
      return {
        label: (
          <span>
            {o.verified_name} ({o.display_phone_number})
             <Tag
              style={{
                fontSize: "10px",
                padding: "2px 4px",
                marginLeft: "4px"
              }}
              >
                {o.account_mode}
            </Tag>
          </span>
        ),
        phoneNumber: o.display_phone_number,
        accountMode: o.account_mode,
        value: waId,
        isDisabled: !ready,
        phoneNumberId: o.id
      }
    })
  }

  const renderSetupContent = () => {
     if (step === 0) {
      return (
        <>
            <div style={{ display: "flex", alignItems: "center", padding: "8px 0"}}>
            <span style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "24px", height: "24px", borderRadius: "12px", background: "#0063f0", color: "#ffffff" }}>1</span>
              <span style={{ paddingLeft: "8px", fontSize: "1.1rem", fontWeight: "bold", color: "#626262" }}>Initialization</span>
            </div>
            <div style={{ padding: "16px 0", color: "#626262", fontSize: "0.95rem" }}>
              <p>In this process, you will be setting up either a full-featured Live phone number or a trial Sandbox phone number depending on a number of criteria.</p>
              <p>You would be setting up a Live phone number if <b>ALL</b> of the following criteria match:</p>

              <li>Your Facebook Business Manager is verified; and</li>
              <li>Your WhatsApp Business Account has passed the WhatsApp Commerce Policy check; and</li>
              <li>This is <b>NOT</b> the first number that added to your WhatsApp business account</li>

              <p>You would be setting up a Sandbox phone number if ANY of the following criteria matches:</p>

              <li>Your Facebook Business Manager is <b>NOT</b> verified; or</li>
              <li>Your WhatsApp Business Account has <b>NOT</b> passed the WhatsApp Commerce Policy check; or</li>
              <li>This is the first number added to the WhatsApp business account</li>
            </div>
            <NewBoxButton
              text="Start Setup"
              primary
              style={{ width: "100%", marginTop: "32px", fontSize: "1rem" }}
              loading={loading}
              onClick={async () => {
                await embedSignup()
              }}
            />
            {loading &&
              <div style={{ display: "flex", justifyContent: "center", fontSize: "0.75rem", marginTop: "8px" }}>
                <span style={{ color: "#ffffff", background: "#929292", padding: "4px 8px", borderRadius: "4px" }}>Loading, Please do not refresh this page.</span>
              </div>
            }
            {signUpError &&
              <div style={{ display: "flex", justifyContent: "center", fontSize: "0.75rem", marginTop: "4px" }}>
                <span style={{ color: "#cc0000", padding: "4px" }}>{signUpError}</span>
              </div>
            }
          </>
      )
    }
    else if (step === 1) {
      return (
        <>
          <div>
            <div style={{ display: "flex", alignItems: "center", padding: "8px 0" }}>
              <span style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "24px", height: "24px", borderRadius: "12px", background: "#0063f0", color: "#ffffff" }}>2</span>
              <span style={{ paddingLeft: "8px", fontSize: "1.1rem", fontWeight: "bold", color: "#626262" }}>Deployment</span>
            </div>
            <div style={{ padding: "8px 0"}}>
              <div style={{ paddingBottom: "4px", color: "#626262", fontSize: "0.75rem"}}>Please select your WhatsApp Business Account</div>
              <Select
                value={variables.wabaId && {
                  label: `${variables.name} (${variables.wabaId})`,
                  value: variables.wabaId
                }}
                options={options}
                onChange={(value) => {
                  dispatch({
                    type: "UPDATE_ACCOUNT",
                    payload: {
                      wabaId: value.value,
                      name: value.name,
                      namespace: value.namespace,
                    }
                  })
                }}
              />
            </div>
          </div>
          <div style={{ padding: "8px 0"}}>
            <div style={{ paddingBottom: "4px", color: "#626262", fontSize: "0.75rem"}}>Please select a Phone Number</div>
            <Select
              value={variables.phoneNumber && {
                label: (
                  <span>
                    {variables.name}
                    ({variables.phoneNumber})
                      <Tag
                        style={{
                          fontSize: "10px",
                          padding: "2px 4px",
                          marginLeft: "4px"
                        }}
                      >
                        {variables.accountMode}
                    </Tag>
                  </span>
                ),
                value: variables.phoneNumber
              }}
              options={phoneNumberOptions}
              onChange={(value) => {
                let phoneNumCc = null
                const cc = /^\+(\d+)/g.exec(value.phoneNumber)?.[1]
                if (cc) {
                  phoneNumCc = cc
                }
                dispatch({
                  type: "UPDATE_NUMBER",
                  payload: {
                    phoneNumber: value.value,
                    phoneNumberCC: phoneNumCc,
                    accountMode: value.accountMode,
                    formattedPhoneNumber: value.formattedPhoneNumber || value.phoneNumber,
                    phoneDisplayName: value.phoneDisplayName,
                    phoneNumberId: value.phoneNumberId,
                  }
                })
              }}
            />
          </div>
          <div style={{ padding: "8px 0"}}>
            <div style={{ color: "#626262", fontSize: "0.75rem"}}>6-Digit Registration Code</div>
            <PinInput
              digit={6}
              onChange={(value) => {
                dispatch({
                  type: "UPDATE_PIN",
                  payload: {
                    pin: value
                  }
                })
              }}
            />
          </div>
          {migrationFlow && 
            <>
              <div style={{ padding: "8px 0" }}>
                <div style={{ color: "#626262", fontSize: "0.75rem" }}>Backup Data</div>
                <TextArea
                  value={variables?.backupData}
                  onChange={(e) => {
                    dispatch({
                      type: "UPDATE_BACKUP_DATA",
                      payload: {
                        backupData: e.target.value
                      }
                    })
                  }}
                />
              </div>
              <div style={{ padding: "8px 0" }}>
                <div style={{ color: "#626262", fontSize: "0.75rem" }}>Backup Password</div>
                <TextInput
                  type="password"
                  value={variables?.backupPassword}
                  onChange={(e) => {
                    dispatch({
                      type: "UPDATE_BACKUP_PASSWORD",
                      payload: {
                        backupPassword: e.target.value
                      }
                    })
                  }}
                />
              </div>
            </>
          }
          <div style={{ marginTop: "32px" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    textDecoration: "underline",
                    fontSize: "0.8rem",
                    color: "#929292",
                    marginBottom: "8px",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    if (migrationFlow) {
                      dispatch({
                        type: "UPDATE_BACKUP_DATA",
                        payload: {
                          backupData: null
                        }
                      })
                      dispatch({
                        type: "UPDATE_BACKUP_PASSWORD",
                        payload: {
                          backupPassword: null
                        }
                      })
                    }
                    setMigrationFlow(!migrationFlow)
                  }}
                >
                {migrationFlow ?
                  "Back to Deployment"
                  :
                  "Migrate from On-Premise?"
                }
                </div>
              </div>
            {migrationFlow ?
              <NewBoxButton
                primary
                disabled={
                  !variables?.wabaId || 
                  !variables?.pin || 
                  !variables?.phoneNumber || 
                  !variables?.phoneNumberId || 
                  variables?.pin?.length !== 6 || 
                  !variables?.backupData || 
                  !variables?.backupPassword
                }
                text="Migrate"
                loading={deploying}
                onClick={() => {
                  finishWhatsappCloudSetup()
                }}
                style={{ width: "100%", fontSize: "1rem" }}
              />
              :
              <NewBoxButton
                primary
                disabled={
                  !variables?.wabaId || 
                  !variables?.pin || 
                  !variables?.phoneNumber || 
                  !variables?.phoneNumberId || 
                  variables?.pin?.length !== 6
                }
                text="Deploy Now"
                loading={deploying}
                onClick={() => {
                  finishWhatsappCloudSetup()
                }}
                style={{ width: "100%", fontSize: "1rem" }}
              />
            }
            {deploying &&
              <div style={{ display: "flex", justifyContent: "center", fontSize: "0.75rem", marginTop: "4px" }}>
                <span style={{ color: "#ffffff", background: "#929292", padding: "4px", borderRadius: "4px" }}>Deploying, Please do not refresh this page.</span>
              </div>
            }
            {deployError &&
              <div style={{ display: "flex", justifyContent: "center", fontSize: "0.75rem", marginTop: "4px" }}>
                <span style={{ color: "#cc0000", padding: "4px" }}>{deployError}</span>
              </div>
            }
          </div>
        </>
      )
    }
  }

  return (
    <div style={{ paddingTop: "32px", paddingBottom: "32px" }}>
      <div style={{ paddingBottom: "8px", color: "#929292", fontWeight: "bold", fontSize: "1.1rem" }} className="title">WhatsApp Business Account Setup</div>
      <div style={{ height: "calc(100% - 64px)" }}>
       {renderSetupContent()}
      </div>
    </div>
  )
}

export default SetUp