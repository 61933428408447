import styled from "styled-components"

export const UploadLabel = styled.label`
  text-align: center;
  height: 100%;
  margin-right: "4px";
  position: relative;
`

export const UploadFileButton = styled.div`
  background-color: #ffffff;
  color: ${props => props.theme.themeColor};
  border: 1px solid ${props => props.theme.themeColor};
  border-radius: 4px;
  padding: 9px 16px;
  user-select: none;
  text-align: center;
  cursor: ${props => (props.isDisabled ? "not-allowed" : "pointer")};
  font-size: 0.8rem;
`

export const FileInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`

export const LoadingContainer = styled.div`
  min-height: 200px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  >svg {
    width: 28px !important;
    height: 28px;
    color: ${props => props.theme.gray4};
  }
`

export const TextInput = styled.input`
  font-size: 1em;
  color: #626262;
  outline: none;
  margin-bottom: 3px;
  margin-top: 2px;
  margin-right: 4px;
  padding: 6px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow: hidden;
  overflow-wrap: break-word;
  width: 100%;
  box-sizing: border-box;
  background-color: ${props => props.disabled && props.theme.paleBackground};
`

export const Filename = styled.div`
  margin-left: 8px;
  padding: 10px;
  border: 1px dashed ${props => props.theme.gray2};
  font-size: 0.7rem;
  border-radius: 4px;
  color: ${props => props.theme.gray6};
`

export const MockImage = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.gray1};

  >svg{
    color: #ffffff;
  }
`

export const ProfileStatus = styled.div`
  display: flex; 
  align-items: start;
  margin-left: 8px;
  font-size: 0.75rem;
  color: ${props => props.success ? props.theme.success : props.theme.error};

  >span {
    padding-left: 4px;
  }
`

export const ProfilePicStatusContainer = styled.div`
  display: flex; 
  align-items: center; 
  font-size: 0.75rem;
  padding-left: 8px;
  color: ${props => props.theme.gray6};
`

export const ExternalLink = styled.a`
  padding-left: 8px;
  >.icon {
    color: ${props => props.theme.gray6};

    &:hover {
      color: ${props => props.theme.primary};
    }
  }
`

export const QualityRating = styled.div`
  align-items: center;
  display: flex;
  font-weight: bold;
  padding-bottom: 8px;
  color: ${(props) => {
    switch (props.rating) {
      case "GREEN":
        return props.theme.success
      case "YELLOW":
        return props.theme.warning
      case "RED":
        return props.theme.error
      default:
        return props.theme.gray3
    }
  }};

  >.icon {
    padding-right: 4px;
  }
`