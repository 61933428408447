import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { CopyToClipboard } from "react-copy-to-clipboard"

import NewBoxButton from "../Radiate/NewBoxButton/NewBoxButton"
import * as Styles from "./AccessTokenManagerStyles"

const SERVER_API_PATH = process.env.REACT_APP_SERVER_API_PATH

const AccessTokenManager = ({
  info,
  payload,
  signedContext,
}) => {
  const [ accessToken, setAccessToken ] = useState(null)
  const [ accessTokens, setAccessTokens ] = useState([])

  useEffect(() => {
    fetch(`${SERVER_API_PATH}/access-token/list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payload,
        signedContext,
      })
    })
      .then(res => res.json())
      .then((result) => {
        if (result.ok) {
          setAccessTokens(result.accessTokens)
        }
      })
  }, [])
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center", padding: "32px 8px 32px 8px" }}>
        <div style={{ width: "30%", paddingRight: "48px", color: "#707070", boxSizing: "border-box" }}>
          <div style={{ fontWeight: "bold", fontSize: "20px" }}>Generate Access Token</div>
          <p
            style={{
              fontSize: "14px"
            }}
          >
            The access token generated allows third-party access to our API
          </p>
        </div>
        <div style={{ width: "70%" }}>
          <NewBoxButton
            style={{ fontSize: "1rem" }}
            text="Generate"
            primary
            onClick={async () => {
              const result = await fetch(`${SERVER_API_PATH}/access-token/generate`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  payload,
                  signedContext,
                })
              }).then(res => res.json())
              if (result.ok) {
                setAccessToken(result.accessToken)
              }
            }}
          />
          {accessToken && (
            <div
              style={{
                marginTop: "16px",
                display: "flex",
                alignItems: "center",
                background: "#eee",
                padding: "10px 8px 10px 10px",
                fontSize: "12px",
                borderRadius: "4px",
                color: "#444",
                lineBreak: "anywhere",
              }}
            >
              <span>
                {accessToken}
              </span>
              <CopyToClipboard
                text={accessToken}
              >
                <FontAwesomeIcon style={{
                  marginLeft: "8px",
                  marginRight: "4px",
                  color: "#777",
                  cursor: "pointer"
                }}icon={faCopy} />
              </CopyToClipboard>
            </div>
          )}
        </div>
      </div>
      <div style={{ height: "1px", background: "#e8e7e8" }} />
      <div style={{ display: "flex", justifyContent: "center", padding: "32px 8px 32px 8px" }}>
        <div style={{ width: "30%", paddingRight: "48px", color: "#707070", boxSizing: "border-box" }}>
          <div style={{ fontWeight: "bold", fontSize: "20px" }}>Access Tokens</div>
          <p
            style={{
              fontSize: "14px"
            }}
          >
            You can manage the existing access tokens here
          </p>
        </div>
        <div style={{ width: "70%" }}>
          <Styles.TableContainer>
            <Styles.StyledTable
              data={accessTokens}
              noOuterBorder
              displayFields={[{
                label: "Access Token",
                key: "accessToken"
              }, {
                label: "Issued At",
                key: "issuedAt"
              }]}
            />
          </Styles.TableContainer>
        </div>
      </div>
    </div>
  )
}

export default AccessTokenManager
