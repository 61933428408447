import styled from "styled-components"

export const BodyContainer = styled.div`
  padding: 0 64px;
  box-sizing: border-box;
  position: relative;
  height: 100%;

  >.separator {
    background: ${props => props.theme.gray1};
    margin-top: 32px;
    height: 1px;
    width: 100%;
  }
`

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  >.overlay-content {
    width: 400px;
    padding: 16px;
    background: #ffffff;
    border-radius: 8px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

    .unsubscribe-text {
      font-size: 0.8rem;
      color: ${props => props.theme.gray7};
      padding-bottom: 4px;
    }

    >.resubscribe-button {
      opacity: 1;
      z-index: 101;
    }
  }
}
`

export const LoadingContainer = styled.div`
  min-height: 200px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  >svg {
    width: 28px !important;
    height: 28px;
    color: ${props => props.theme.gray4};
  }
`